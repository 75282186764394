@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&family=Source+Sans+Pro:wght@200&family=Spartan&display=swap');

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
}

h1,
h2,
h3,
h4,
span,
p,
div,
a,
li,
ul {
    font-family: 'Spartan', sans-serif;
}

.App {
    background: #3b3b3b;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
}

h1,
h2 {
    color: white;
    text-decoration: none;
    user-select: none;
}

h1 {
    text-align: center;
    margin: 1rem 0;
}

p,
a {
    font-weight: 600;
    color: white;
    text-decoration: none;
    user-select: none;
}

.menu {
    display: flex;
    justify-content: center;
}

.categoriesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.categoriesContainer a {
    flex: 0 0 33.333333%;
    text-align: center;
}

.categoryContainer {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.streamsList {
    overflow-x: hidden;
    overflow-y: scroll;
    text-align: center;
    cursor: pointer;
    flex: 1;
    margin-left: 2rem;
}

.streamOption {
    padding: 1rem;
    border-radius: 25px;
}

.categoryOption p {
    margin: 0.25rem 3rem;
    padding: 1rem;
    border-radius: 25px;
}

.streamOption:hover,
.categoryOption p:hover {
    background-color: #333;
}

.videoWrapper {
    /* display: flex; */
    flex-direction: column;
    /* 
  justify-content: center;
  align-items: center; */
    flex: 4;
    width: 100%;
    /* margin: auto; */
}

.player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.streamInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.streamControls {
    display: flex;
    justify-content: center;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 2rem;
    font-weight: 600;
}

.searchWrapper {
    text-align: center;
    margin: 0 0 1rem 2rem;
}

.search-input {
    padding: 10px 0 10px 10px;
    width: 75%;
}

.search-input:focus {
    outline: none;
}

.backLink {
    display: flex;
    align-items: center;
    margin: 0rem auto;
    font-size: 1.2rem;
}

.backToCategories:hover,
.backLink:hover,
.logoutBtn:hover {
    opacity: 60%;
}

.prevBtn,
.nextBtn {
    background-color: blue;
    cursor: pointer;
    padding: 0.5rem 0.25rem;
    margin: 0;
    width: 6rem;
    text-align: center;
}

.prevBtn {
    margin-right: 1rem;
}

.nextBtn {
    margin-left: 1rem;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginFieldLabel {
    margin-bottom: 0;
}

.loginField {
    margin: 0.5rem 0 1rem 0;
    width: 50%;
    padding: 0.5rem;
}

.loginBtn {
    margin: 1rem 0;
    width: 8rem;
    padding: 0.5rem;
    font-size: 1rem;
}

.logoutBtn {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.2rem 0.5rem 0 0;
    cursor: pointer;
}
